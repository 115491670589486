import React, { useState } from "react";
import { Page, TextField, Card, FormLayout, Button } from "@shopify/polaris";
import api from "../../Api.js";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function Login() {
	const dispatch = useDispatch<AppDispatch>();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const login = () => {
		setLoading(true);

		api
			.post("/login.json", { email, password })
			.then((result) => {
				if (result.data.success && result.data.token) {
					localStorage.setItem("token", result.data.token);
					api
						.get("/me.json")
						.then((result) => {
							dispatch({ type: "SET_USER", user: result.data });
						})
						.finally(() => {
							setLoading(false);
						});

					return;
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error("error:", error);
				setLoading(false);
			});
	};
	const register = () => {
		navigate("/register");
	};

	return (
		<Page narrowWidth title="Sign in">
			<Card sectioned>
				<FormLayout>
					<TextField type="email" autoComplete="off" label="E-mail" value={email} onChange={setEmail} />
					<TextField type="password" autoComplete="off" label="Password" value={password} onChange={setPassword} />
					<Button primary loading={loading} onClick={login}>
						Log in
					</Button>
					<Button plain onClick={register}>
						Register new account
					</Button>
				</FormLayout>
			</Card>
		</Page>
	);
}

export default Login;
