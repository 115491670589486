import { Page, Button } from "@shopify/polaris";
import api from "../../Api.js";

function Dashboard() {
	const addGmail = (): void => {
		api
			.get("/google/auth.json")
			.then((result) => {
				window.location.href = result.data.url;
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {});
	};

	const addOutlook = (): void => {
		api
			.get("/microsoft/auth.json")
			.then((result) => {
				window.location.href = result.data.url;
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {});
	};

	return (
		<Page fullWidth title="Dashboard">
			<Button onClick={addGmail}>Add gmail</Button>
			<Button onClick={addOutlook}>Add outlook</Button>
		</Page>
	);
}

export default Dashboard;
