import React, { useEffect, useState } from "react";
import api from "../../Api.js";
import { Page, IndexTable, Badge, useIndexResourceState, EmptySearchResult, Link, Text } from "@shopify/polaris";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function ConversationsIndex() {
	const navigate = useNavigate();
	const [first, setFirst] = useState(false);
	const [loading, setLoading] = useState(false);
	const [conversations, setConversations] = useState<Conversation[]>([]);
	const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(conversations);

	useEffect(() => {
		fetchConversations();
	}, []);

	const fetchConversations = () => {
		setLoading(true);
		var params = {};
		api
			.get("/conversations.json", { params })
			.then((result) => {
				setFirst(false);
				setConversations(result.data.conversations);
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const emptyStateMarkup = (
		<EmptySearchResult title="No conversations yet" description="Create a new conversation or start a campaign" withIllustration />
	);

	return (
		<Page
			title="My Conversations"
			primaryAction={{
				content: "Create new Conversation",
				onAction: () => {
					navigate("/conversations/new");
				},
			}}
		>
			{!first && (
				<IndexTable
					resourceName={{
						singular: "conversation",
						plural: "conversations",
					}}
					emptyState={emptyStateMarkup}
					selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
					onSelectionChange={handleSelectionChange}
					itemCount={conversations.length}
					loading={loading}
					headings={[{ title: "E-mail" }, { title: "Subject" }, { title: "Status" }, { title: "Created" }]}
				>
					{conversations.map((conversation, index) => {
						return (
							<IndexTable.Row id={conversation.id} key={conversation.id} selected={selectedResources.includes(conversation.id)} position={index}>
								<IndexTable.Cell>
									<Link dataPrimaryLink onClick={() => navigate("/conversations/" + conversation.id)}>
										<Text variant="bodyMd" fontWeight="bold" as="span">
											{conversation.to_email}
										</Text>
									</Link>
								</IndexTable.Cell>
								<IndexTable.Cell>{conversation.subject}</IndexTable.Cell>
								<IndexTable.Cell>
									<Badge>{conversation.status}</Badge>
								</IndexTable.Cell>
								<IndexTable.Cell>{moment(conversation.created_at).calendar()}</IndexTable.Cell>
							</IndexTable.Row>
						);
					})}
				</IndexTable>
			)}
		</Page>
	);
}
export default ConversationsIndex;
