import React, { useEffect, useState } from "react";
import "@shopify/polaris/build/esm/styles.css";
import "./App.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider, Frame, TopBar, Navigation } from "@shopify/polaris";
import api from "./Api.js";
import { RootState, AppDispatch } from "./store";
import { Route, Routes } from "react-router-dom";
import { HomeMajor, OrdersMajor, ChatMajor, EmailNewsletterMajor } from "@shopify/polaris-icons";

import Login from "./views/Authentication/Login";
import Register from "./views/Authentication/Register";
import Dashboard from "./views/Dashboard/Dashboard";
import ListIndex from "./views/Lists/ListIndex";
import ListCreate from "./views/Lists/ListCreate";
import ListView from "./views/Lists/ListView";
import ConversationsIndex from "./views/Conversations/ConversationsIndex";
import ConversationCreate from "./views/Conversations/ConversationCreate";
import ConversationView from "./views/Conversations/ConversationView";
import CampaignIndex from "./views/Campaigns/CampaignIndex";
import GoogleAuth from "./views/Integrations/GoogleAuth";
import MicrosoftAuth from "./views/Integrations/MicrosoftAuth";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function App() {
	const user = useSelector((state: RootState) => state.user);
	const dispatch = useDispatch<AppDispatch>();
	const [userMenuActive, setUserMenuActive] = useState(false);
	const [showNavigation, setShowNavigation] = useState(false);
	const navigate = useNavigate();

	const logout = () => {
		localStorage.clear();
		dispatch({ type: "SET_USER", user: null });
	};
	useEffect(() => {
		const getMe = () => {
			api
				.get("/me.json")
				.then((result) => {
					dispatch({ type: "SET_USER", user: result.data });
				})
				.catch((error) => {
					logout();
				});
		};
		const fetchIntegrations = () => {
			api.get("/integrations.json").then((result) => {
				dispatch({ type: "SET_INTEGRATIONS", integrations: result.data.integrations });
			});
		};

		const token = localStorage.getItem("token");

		if (token) {
			getMe();
			fetchIntegrations();
		}
	}, []);

	const token = localStorage.getItem("token");

	if (!user) {
		return (
			<AppProvider i18n={enTranslations}>
				{!token && (
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path="/register" element={<Register />} />
					</Routes>
				)}
			</AppProvider>
		);
	}

	const logo = {
		width: 124,
		topBarSource: "https://cdn.shopify.com/s/files/1/0446/6937/files/jaded-pixel-logo-color.svg?6215648040070010999",
		contextualSaveBarSource: "https://cdn.shopify.com/s/files/1/0446/6937/files/jaded-pixel-logo-gray.svg?6215648040070010999",
		url: "https://app.machosales.com",
		accessibilityLabel: "Jaded Pixel",
	};

	const userMenuActions = [
		{
			items: [{ content: "Log out", onAction: logout }],
		},
	];

	const userMenuMarkup = (
		<TopBar.UserMenu
			actions={userMenuActions}
			name={user.email}
			detail="My account"
			initials="D"
			open={userMenuActive}
			onToggle={() => {
				setUserMenuActive(!userMenuActive);
			}}
		/>
	);

	const topBarMarkup = (
		<TopBar
			showNavigationToggle
			userMenu={userMenuMarkup}
			searchResultsVisible={false}
			searchField={<div>hej</div>}
			searchResults={null}
			onSearchResultsDismiss={() => {}}
			onNavigationToggle={() => {
				setShowNavigation(!showNavigation);
			}}
		/>
	);

	const navigationMarkup = (
		<Navigation location="/">
			<Navigation.Section
				separator
				title="Jaded Pixel App"
				items={[
					{
						label: "Dashboard",
						icon: HomeMajor,
						onClick: () => {
							navigate("/");
						},
					},
					{
						label: "Lists",
						icon: OrdersMajor,
						onClick: () => {
							navigate("/lists");
						},
					},
					{
						label: "Campaigns",
						icon: EmailNewsletterMajor,
						onClick: () => {
							navigate("/campaigns");
						},
					},
					{
						label: "Conversations",
						icon: ChatMajor,
						onClick: () => {
							navigate("/conversations");
						},
					},
				]}
			/>
		</Navigation>
	);
	return (
		<AppProvider i18n={enTranslations}>
			<Frame
				logo={logo}
				topBar={topBarMarkup}
				navigation={navigationMarkup}
				showMobileNavigation={showNavigation}
				onNavigationDismiss={() => {
					setShowNavigation(!showNavigation);
				}}
			>
				<Routes>
					<Route path="/" element={<Dashboard />} />

					<Route path="/lists" element={<ListIndex />} />
					<Route path="/lists/new" element={<ListCreate />} />
					<Route path="/lists/:listId/:view" element={<ListView />} />
					<Route path="/conversations" element={<ConversationsIndex />} />
					<Route path="/conversations/new" element={<ConversationCreate />} />
					<Route path="/conversations/:conversationId" element={<ConversationView />} />
					<Route path="/campaigns" element={<CampaignIndex />} />
					<Route path="/google/callback" element={<GoogleAuth />} />
					<Route path="/microsoft/callback" element={<MicrosoftAuth />} />
					{/*
					<Route path="/campaigns/:id/:view" element={<CampaignView />} />
					<Route path="/campaigns/:id/lists/new" element={<CampaignAddList />} />
					<Route path="/campaigns/:id/google_lists/:sublist_id" element={<GoogleListHitIndex />} />
					*/}
				</Routes>
			</Frame>
		</AppProvider>
	);
}

export default App;
