import React, { useEffect, useState } from "react";
import api from "../../Api.js";
import { Page, IndexTable, useIndexResourceState, EmptySearchResult, Link, Text } from "@shopify/polaris";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function ListIndex() {
	const navigate = useNavigate();
	const [first, setFirst] = useState(false);
	const [loading, setLoading] = useState(false);
	const [lists, setLists] = useState<List[]>([]);
	const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(lists);

	useEffect(() => {
		fetchLists();
	}, []);

	const fetchLists = () => {
		setLoading(true);
		var params = {};
		api
			.get("/lists.json", { params })
			.then((result) => {
				setFirst(false);
				setLists(result.data.lists);
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const emptyStateMarkup = <EmptySearchResult title="No lists yet" description="Create a new list or try changing the filters" withIllustration />;

	return (
		<Page
			title="My Lists"
			primaryAction={{
				content: "Create new List",
				onAction: () => {
					navigate("/lists/new");
				},
			}}
		>
			{!first && (
				<IndexTable
					resourceName={{
						singular: "list",
						plural: "lists",
					}}
					emptyState={emptyStateMarkup}
					selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
					onSelectionChange={handleSelectionChange}
					itemCount={lists.length}
					loading={loading}
					headings={[{ title: "Name" }, { title: "Created" }]}
				>
					{lists.map((list, index) => {
						return (
							<IndexTable.Row id={list.id} key={list.id} selected={selectedResources.includes(list.id)} position={index}>
								<IndexTable.Cell>
									<Link dataPrimaryLink onClick={() => navigate("/lists/" + list.id + "/overview")}>
										<Text variant="bodyMd" fontWeight="bold" as="span">
											{list.title}
										</Text>
									</Link>
								</IndexTable.Cell>
								<IndexTable.Cell>{moment(list.created_at).calendar()}</IndexTable.Cell>
							</IndexTable.Row>
						);
					})}
				</IndexTable>
			)}
		</Page>
	);
}
export default ListIndex;
