const integration = (state: Integration[] = [], action: any) => {
	switch (action.type) {
		case "SET_INTEGRATIONS":
			state = [...action.integrations];
			return state;
		case "ADD_INTEGRATION":
			state = state.slice();
			state.push(action.integration);
			return state;
		case "UPDATE_INTEGRATION":
			state = state.slice();
			for (var i = 0; i < state.length; i++) {
				if (state[i].id === action.integration.id) {
					state[i] = action.integration;
				}
			}
			return state;
		default:
			return state;
	}
};

export default integration;
