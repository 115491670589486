import React, { useEffect, useState } from "react";
import api from "../../Api.js";
import { Page, IndexTable, useIndexResourceState, EmptySearchResult, Link, Text } from "@shopify/polaris";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function CampaignIndex() {
	const navigate = useNavigate();
	const [first, setFirst] = useState(false);
	const [loading, setLoading] = useState(false);
	const [campaigns, setCampaigns] = useState<Campaign[]>([]);
	const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(campaigns);

	useEffect(() => {
		fetchCampaigns();
	}, []);

	const fetchCampaigns = () => {
		setLoading(true);
		var params = {};
		api
			.get("/campaigns.json", { params })
			.then((result) => {
				setFirst(false);
				setCampaigns(result.data.campaigns);
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const emptyStateMarkup = (
		<EmptySearchResult title="No campaigns yet" description="Create a new campaign or try changing the filters" withIllustration />
	);

	return (
		<Page
			title="My Campaigns"
			primaryAction={{
				content: "Create new Campaign",
				onAction: () => {
					navigate("/campaigns/new");
				},
			}}
		>
			{!first && (
				<IndexTable
					resourceName={{
						singular: "campaign",
						plural: "campaigns",
					}}
					emptyState={emptyStateMarkup}
					selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
					onSelectionChange={handleSelectionChange}
					itemCount={campaigns.length}
					loading={loading}
					headings={[{ title: "Name" }, { title: "Created" }]}
				>
					{campaigns.map((campaign, index) => {
						return (
							<IndexTable.Row id={campaign.id} key={campaign.id} selected={selectedResources.includes(campaign.id)} position={index}>
								<IndexTable.Cell>
									<Link dataPrimaryLink onClick={() => navigate("/lists/" + campaign.id + "/overview")}>
										<Text variant="bodyMd" fontWeight="bold" as="span">
											{campaign.title}
										</Text>
									</Link>
								</IndexTable.Cell>
								<IndexTable.Cell>{moment(campaign.created_at).calendar()}</IndexTable.Cell>
							</IndexTable.Row>
						);
					})}
				</IndexTable>
			)}
		</Page>
	);
}
export default CampaignIndex;
