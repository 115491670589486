const user = (state: User | null = null, action: any) => {
	switch (action.type) {
		case "SET_USER":
			var newState = Object.assign({}, state);
			newState = action.user;
			return newState;
		default:
			return state;
	}
};
export default user;
