import React, { Provider, useCallback, useEffect, useState } from "react";
import { Card, ChoiceList, Layout, TextField, Listbox, Icon, FormLayout, Combobox, Text } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import ListProviderFormOptions, { ListProviderOption } from "./ListProviderFormOptions";

import api from "../Api.js";

interface ListProviderOptionSet {
	[key: string]: ListProviderOption[];
}

interface ListProviderFormProps {
	provider: ListProvider;
	onChange: (value: ListProvider) => void;
}
function ListProviderForm(props: ListProviderFormProps) {
	const initialProvider = props.provider;
	const [provider, setProvider] = useState(initialProvider);
	const [options, setOptions] = useState<ListProviderOptionSet>({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		changeType(provider.type);
	}, []);

	useEffect(() => {
		props.onChange(provider);
	}, [provider]);

	const updateKeyword = (index: number, value: string) => {
		provider.keywords[index] = value;

		var keywords = [];
		for (var i = 0; i < provider.keywords.length; i++) {
			if (provider.keywords[i]) {
				keywords.push(provider.keywords[i]);
			}
		}
		keywords.push("");

		provider.keywords = keywords;
		setProvider({ ...provider });
	};

	const changeType = (type: ListProviderType) => {
		setProvider((c) => ({ ...c, ...{ type: type } }));
		setLoading(true);
		api
			.get("/lists/google_lists/options.json")
			.then((result) => {
				setOptions(result.data);
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<React.Fragment>
			<Card sectioned title="Type of list provider">
				<ChoiceList
					title={null}
					choices={[
						{
							label: "Gather prospects from Google Search",
							value: "google",
							renderChildren: (selected) => {
								return (
									selected && (
										<Text as={"span"} color="subdued">
											Our crawler will collect prospects from websites matching desired keywords.
										</Text>
									)
								);
							},
						},
						{
							label: "Gather contacts from LinkedIn",
							value: "linkedin",
							disabled: true,
							renderChildren: (selected) => {
								return (
									selected && (
										<Text as={"span"} color="subdued">
											Use our google chrome plugin to collect information from LinkedIn.
										</Text>
									)
								);
							},
						},
						{
							label: "Import from a company registry",
							value: "companies",
							disabled: true,
							renderChildren: (selected) => {
								return (
									selected && (
										<Text as={"span"} color="subdued">
											Select critieras such as company size, turnover and location.
										</Text>
									)
								);
							},
						},
						{
							label: "Upload a spreadsheet ",
							value: "upload",
							disabled: true,
							renderChildren: (selected) => {
								return (
									selected && (
										<Text as={"span"} color="subdued">
											Upload your own spreadsheet of prospects (xls, csv, etc.)
										</Text>
									)
								);
							},
						},
					]}
					selected={[provider.type]}
					onChange={(v: ListProviderType[]) => {
						changeType(v[0]);
					}}
				/>
			</Card>
			{provider.type === "google" && (
				<Card sectioned title="Google settings">
					<Layout>
						<Layout.Section>
							<FormLayout>
								{provider.keywords.map((keyword, index) => {
									return (
										<TextField
											autoComplete="off"
											key={index}
											label={"Keyword " + (index + 1)}
											helpText={index < 1 ? 'For example "Restaurants in Lund" ' : ""}
											value={keyword}
											onChange={(v) => updateKeyword(index, v)}
										/>
									);
								})}
							</FormLayout>
						</Layout.Section>
						<Layout.Section secondary>
							<FormLayout>
								<ListProviderFormOptions
									onChange={(v) => {
										provider.options.gl = v;
										setProvider({ ...provider });
									}}
									label="Google country"
									value={provider.options.gl}
									options={options.gl}
								/>
								<ListProviderFormOptions
									onChange={(v) => {
										provider.options.hl = v;
										setProvider({ ...provider });
									}}
									label="Google UI Language"
									value={provider.options.hl}
									options={options.hl}
								/>
								<ListProviderFormOptions
									onChange={(v) => {
										provider.options.google_domain = v;
										setProvider({ ...provider });
									}}
									label="Google Domain"
									value={provider.options.google_domain}
									options={options.google_domain}
								/>
								<ListProviderFormOptions
									onChange={(v) => {
										provider.options.location = v;
										setProvider({ ...provider });
									}}
									label="Location"
									value={provider.options.location}
									options={options.location}
								/>
							</FormLayout>
						</Layout.Section>
					</Layout>
				</Card>
			)}
		</React.Fragment>
	);
}

export default ListProviderForm;
