import React, { useEffect, useState } from "react";
import api from "../../Api.js";
import { Page, Card, Button, TextField, FormLayout } from "@shopify/polaris";
import { useParams, useNavigate } from "react-router-dom";
import nl2br from "react-nl2br";

interface AiInput {
    role: string;
    content: string;
};

function ConversationView() {
    const [loading, setLoading] = useState(false);
    const [conversation, setConversation] = useState<Conversation|null>(null);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [messages, setMessages] = useState<ConversationMessage[]>([]);
    const [generating, setGenerating] = useState(false);
    const [content, setContent] = useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit.");
	const [instructions, setInstructions] = useState("");
	let { conversationId } = useParams();

    const generate = () => {
		setGenerating(true);
		var params = {};
        var oldMessages:AiInput[] = [];

        messages.forEach((message) => {
            if (message.instructions) {
                oldMessages.push({role: "system", content: message.instructions});
            }
            if (message.incoming) {
                oldMessages.push({role: "user", content: message.message});
            }
            else {
                oldMessages.push({role: "assistant", content: message.message});
            }
        });

        if (instructions) {
            let newMessage:AiInput = {role: "system", content: instructions};
            oldMessages.push(newMessage);
        }

		api
			.post(
				"/openai/generate.json",
				{
					messages: oldMessages,
				},
				{ params }
			)
			.then((result) => {
				setContent(result.data.choices[0].message.content);
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setGenerating(false);
			});
	};

    useEffect(() => {
		fetchConversation();
        fetchMessages();
	}, [conversationId]);

    const fetchConversation = () => {
		setLoading(true);
		api
			.get("/conversations/" + conversationId + ".json")
			.then((result) => {
                setConversation(result.data.conversation);
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

    const fetchMessages = () => {
		setLoadingMessages(true);
		api
			.get("/conversations/" + conversationId + "/messages.json")
			.then((result) => {
                setMessages(result.data.messages);
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setLoadingMessages(false);
			});
	};

    const stripHistory = (str:string) => {
        str = str.split("-- \n")[0];
        str = str.split("--\n")[0];
        str = str.split("-----Original Message-----")[0];
        str = str.split("________________________________")[0];
        str = str.split("On ")[0];
        str = str.split("From: ")[0];
        str = str.split("Sent from my iPhone")[0];
        str = str.split("Sent from my BlackBerry")[0];

        /*
        Lines that equal '-- \n' (standard email sig delimiter)
        Lines that equal '--\n' (people often forget the space in sig delimiter; and this is not that common outside sigs)
        Lines that begin with '-----Original Message-----' (MS Outlook default)
        Lines that begin with '________________________________' (32 underscores, Outlook again)
        Lines that begin with 'On ' and end with ' wrote:\n' (OS X Mail.app default)
        Lines that begin with 'From: ' (failsafe four Outlook and some other reply formats)
        Lines that begin with 'Sent from my iPhone'
        Lines that begin with 'Sent from my BlackBerry'
        */
       return str.trim();

    }

	return (
		<Page
			title="Conversation"
		>
			<Card>
				{messages.map((message) => {
                    return (
                        <>
                        {message.instructions && <Card.Section>
                            <i>System</i>
                                <p>{nl2br(message.instructions)}</p>
                            </Card.Section>}
                        <Card.Section key={message.id}>
                            <div>
                                {message.incoming ? '<':'>'}<i>{message.from_email}</i>
                                <p>{nl2br(stripHistory(message.message))}</p>
                            </div>
                        </Card.Section> 
                        </>
                    );
                })}
			</Card>

            <Card sectioned>
				<FormLayout>
					<TextField
						prefix={
							<Button onClick={generate} loading={generating}>
								Generate
							</Button>
						}
						autoComplete="off"
						label="Instructions"
						multiline={5}
						value={instructions}
						onChange={setInstructions}
					/>
					<TextField autoComplete="off" label="Content" multiline={5} value={content} onChange={setContent} />
				</FormLayout>
			</Card>
		</Page>
	);
}
export default ConversationView;
