import { Page } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import api from "../../Api.js";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function MicrosoftAuth() {
	const dispatch = useDispatch<AppDispatch>();
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		connectOutlook();
	}, []);

	const createIntegration = (type: IntegrationType, authentication: IntegrationAuthentication) => {
		setSaving(true);
		api
			.post("/integrations.json", { type, authentication })
			.then((result) => {
				dispatch({ type: "ADD_INTEGRATION", integration: result.data.integration });
				navigate("/campaigns/new");
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setSaving(false);
			});
	};

	const connectOutlook = () => {
		setLoading(true);
		const params = Object.fromEntries(new URLSearchParams(window.location.search));

		api
			.get("/microsoft/callback.json", { params })
			.then((result) => {
				createIntegration("outlook", { access_token: result.data.tokens.accessToken, refresh_token: result.data.tokens.refreshToken });
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Page fullWidth title="Dashboard">
			hej
		</Page>
	);
}
export default MicrosoftAuth;
