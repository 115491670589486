import { useState, useEffect } from "react";
import {
	Page,
	Tabs,
	Card,
	Badge,
	useIndexResourceState,
	Text,
	ResourceList,
	ResourceItem,
	TextContainer,
	Layout,
	SkeletonPage,
	SkeletonBodyText,
	SkeletonDisplayText,
	IndexTable,
	Stack,
} from "@shopify/polaris";

import api from "../../Api.js";
import { useParams, useNavigate } from "react-router-dom";

function ListView() {
	const navigate = useNavigate();
	const [loadingContacts, setLoadingContacts] = useState(false);
	const [contacts, setContacts] = useState<ListContact[]>([]);
	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<List | null>(null);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [loadingProviders, setLoadingProviders] = useState(false);
	const [providers, setProviders] = useState<GoogleList[]>([]);
	const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(contacts);
	let { listId, view } = useParams();

	const tabs = [
		{
			id: "overview",
			content: "Overview",
			accessibilityLabel: "Overview",
			panelID: "overview",
		},
		{
			id: "contacts",
			content: "Contacts",
			panelID: "contacts",
		},
	];

	useEffect(() => {
		fetchList();
	}, [listId]);

	useEffect(() => {
		navigate("/lists/" + listId + "/" + tabs[selectedTabIndex].id, { replace: true });
	}, [selectedTabIndex]);

	const fetchList = () => {
		setLoading(true);
		api
			.get("/lists/" + listId + ".json")
			.then((result) => {
				setList(result.data.list);
				fetchContacts();
				fetchProviders();
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchContacts = () => {
		setLoadingContacts(true);
		api
			.get("/lists/" + listId + "/contacts.json")
			.then((result) => {
				setContacts(result.data.contacts);
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setLoadingContacts(false);
			});
	};

	const fetchProviders = () => {
			setLoadingProviders(true);
			api
				.get("/lists/" + listId + "/google_lists.json")
				.then((result) => {
					for (var i = 0; i < result.data.google_lists.length; i++) {
						if (result.data.google_lists[i].status != "complete") {
							setTimeout(fetchProviders, 10000);
							setTimeout(fetchContacts, 10000);
							break;
						}
					}
					setProviders(result.data.google_lists);
				})
				.catch((error) => {
					console.log("error:", error);
				})
				.finally(() => {
					setLoadingProviders(false);
				});
	};

	const getSublistBadge = (provider:GoogleList) => {
		return <Badge>{provider.status}</Badge>;
	};

	if (loading) {
		return (
			<SkeletonPage primaryAction>
				<Layout>
					<Layout.Section>
						<Card sectioned>
							<SkeletonBodyText />
						</Card>
						<Card sectioned>
							<TextContainer>
								<SkeletonDisplayText size="small" />
								<SkeletonBodyText />
							</TextContainer>
						</Card>
						<Card sectioned>
							<TextContainer>
								<SkeletonDisplayText size="small" />
								<SkeletonBodyText />
							</TextContainer>
						</Card>
					</Layout.Section>
					<Layout.Section secondary>
						<Card>
							<Card.Section>
								<TextContainer>
									<SkeletonDisplayText size="small" />
									<SkeletonBodyText lines={2} />
								</TextContainer>
							</Card.Section>
							<Card.Section>
								<SkeletonBodyText lines={1} />
							</Card.Section>
						</Card>
						<Card subdued>
							<Card.Section>
								<TextContainer>
									<SkeletonDisplayText size="small" />
									<SkeletonBodyText lines={2} />
								</TextContainer>
							</Card.Section>
							<Card.Section>
								<SkeletonBodyText lines={2} />
							</Card.Section>
						</Card>
					</Layout.Section>
				</Layout>
			</SkeletonPage>
		);
	}

	return (
		<Page
			title={list?.title}
			breadcrumbs={{
				content: "Go back",
				onAction: () => {
					navigate(-1);
				},
			}}
		>
			<div style={{ marginBottom: "1rem" }}>
				<Card>
					<Tabs tabs={tabs} selected={selectedTabIndex} onSelect={setSelectedTabIndex} fitted />
				</Card>
			</div>
			{selectedTabIndex == 0 && (
				<Stack>
					<Card title="Number of contacts" sectioned>
						<Text variant="heading4xl" as="h1" fontWeight="regular" alignment="end">
							{contacts.length}
						</Text>
					</Card>
				</Stack>
			)}
			{selectedTabIndex == 1 && (
				<Layout>
					<Layout.Section secondary>
						<Card
							title="Google searches"
							actions={[
								{
									content: "Add more",
									onAction: () => {
										navigate("/lists/" + list?.id + "/providers/new");
									},
								},
							]}
						>
							<ResourceList<GoogleList>
								loading={loadingProviders}
								resourceName={{ singular: "keyword", plural: "keywords" }}
								items={providers}
								renderItem={(provider) => {
									return (
										<ResourceItem
											id={provider.id}
											onClick={() => navigate("/lists/" + list?.id + "/google_lists/" + provider.id)}
										>
											<Text variant="bodyMd" fontWeight="bold" as="span">
												{provider.q}
											</Text>
											{getSublistBadge(provider)}
											{provider.crawled}/{provider.hits}
										</ResourceItem>
									);
								}}
							/>
						</Card>
					</Layout.Section>
					<Layout.Section>
						<Card title="Contacts">
							<IndexTable
								resourceName={{
									singular: "contact",
									plural: "contacts",
								}}
								selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
								onSelectionChange={handleSelectionChange}
								selectable={true}
								itemCount={contacts.length}
								loading={loadingContacts}
								headings={[{ title: "E-mail" }, { title: "Status" }]}
							>
								{contacts.map((contact, index) => {
									return (
										<IndexTable.Row id={contact.id} key={contact.id} selected={selectedResources.includes(contact.id)} position={index}>
											<IndexTable.Cell>
												<Text variant="bodyMd" as="span">
													{contact.email}
												</Text>
											</IndexTable.Cell>
											<IndexTable.Cell>
												<Badge>Not contacted</Badge>
											</IndexTable.Cell>
										</IndexTable.Row>
									);
								})}
							</IndexTable>
						</Card>
					</Layout.Section>
				</Layout>
			)}
		</Page>
	);
}
export default ListView;
