/* eslint-disable import/prefer-default-export */
import { createStore, combineReducers } from "redux";
import user from "./reducers/user";
import integrations from "./reducers/integrations";
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const reducers = {
	user,
	integrations,
};
const combinedReducer = combineReducers(reducers);

const initialState = {
	integrations: [],
};
export const store = createStore(combinedReducer, initialState);
