import { useEffect } from "react";
import { Listbox, Icon, Combobox } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";

export type ListProviderOption = {
	label?: string;
	value: string;
};

interface ListProviderFormOptionProps {
	label: string;
	value: string;
	options?: ListProviderOption[];
	onChange: (value: string) => void;
}
function ListProviderFormOptions({ label, value, options, onChange }: ListProviderFormOptionProps) {
	useEffect(() => {
		if (options && options.length > 0 && !value) {
			onChange(options[0].value);
		}
	}, [value, options]);
	return (
		<Combobox
			activator={<Combobox.TextField autoComplete="off" prefix={<Icon source={SearchMinor} />} onChange={onChange} label={label} value={value} />}
		>
			{options ? (
				<Listbox onSelect={onChange}>
					{options.map((option) => {
						var label = option.label || option.value;
						return (
							<Listbox.Option key={option.value} value={option.value} selected={value === option.value} accessibilityLabel={label}>
								{label}
							</Listbox.Option>
						);
					})}
				</Listbox>
			) : null}
		</Combobox>
	);
}

export default ListProviderFormOptions;
