import React, { useState } from "react";
import { Page, PageActions } from "@shopify/polaris";

import ListProviderForm from "../../components/ListProviderForm";

import api from "../../Api.js";
import { useNavigate } from "react-router-dom";

function ListCreate() {
	const navigate = useNavigate();
	const [saving, setSaving] = useState(false);
	const [providers, setProviders] = useState<ListProvider[]>([
		{
			type: "google",
			keywords: [""],
			options: {
				gl: "se",
				google_domain: "google.se",
				hl: "sv",
				location: "Skane County,Sweden",
			},
		},
	]);

	const createList = () => {
		setSaving(true);
		const data = Object.assign({ title: providers[0].keywords[0] }, { providers });

		api
			.post("/lists.json", data)
			.then((result) => {
				navigate("/lists/" + result.data.list.id + "/overview");
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setSaving(false);
			});
	};

	return (
		<Page
			primaryAction={{
				content: "Create & Import",
				disabled: providers.length < 1 || providers[0].keywords.length < 1 || providers[0].keywords[0] === "",
				loading: saving,
				onAction: createList,
			}}
			title="New List"
			breadcrumbs={{
				content: "Go back",
				onAction: () => {
					navigate(-1);
				},
			}}
		>
			{providers.map((provider) => {
				return (
					<ListProviderForm
						provider={provider}
						onChange={(v) => {
							const index = providers.indexOf(provider);
							providers[index] = v;
							setProviders(providers.slice());
						}}
					/>
				);
			})}
			<PageActions
				primaryAction={{
					content: "Create & Import",
					loading: saving,
					disabled: providers.length < 1 || providers[0].keywords.length < 1 || providers[0].keywords[0] === "",
					onAction: createList,
				}}
			/>
		</Page>
	);
}
export default ListCreate;
