import React, { useEffect, useState } from "react";
import api from "../../Api.js";
import { Page, Card, FormLayout, Select, TextField, Button } from "@shopify/polaris";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

function ConversationCreate() {
	const integrations = useSelector((state: RootState) => state.integrations);
	const user = useSelector((state: RootState) => state.user);
	const navigate = useNavigate();
	const [selectedIntegration, setIntegration] = useState<Integration | undefined>(integrations.length > 0 ? integrations[0] : undefined);
	const [email, setEmail] = useState(user?.email);
	const [subject, setSubject] = useState("Sample subject");
	const [content, setContent] = useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit.");
	const [instructions, setInstructions] = useState(
		"Pretending you are a sales representative selling websites, pretend that you saw their website and try offer a meeting. Start with a funny joke!"
	);
	const [saving, setSaving] = useState(false);
	const [generating, setGenerating] = useState(false);

	const generate = () => {
		setGenerating(true);
		var params = {};
		api
			.post(
				"/openai/generate.json",
				{
					messages: [{ role: "system", content: instructions }],
				},
				{ params }
			)
			.then((result) => {
				setContent(result.data.choices[0].message.content);
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setGenerating(false);
			});
	};

	const createConversation = () => {
		setSaving(true);
		var params = {};
		api
			.post(
				"/conversations.json",
				{
					content,
					subject,
					email,
					integration_id: selectedIntegration?.id,
					instructions,
				},
				{ params }
			)
			.then((result) => {
				navigate(-1);
			})
			.catch((error) => {
				console.log("error:", error);
			})
			.finally(() => {
				setSaving(false);
			});
	};

	return (
		<Page
			title="Start new Conversation"
			primaryAction={{
				content: "Send e-mail",
				onAction: createConversation,
				loading: saving,
			}}
		>
			<Card sectioned>
				<FormLayout>
					<Select
						label="Send from"
						value={selectedIntegration?.id + ""}
						onChange={(v) => {
							setIntegration(integrations.find((integration) => integration.id == parseInt(v)));
						}}
						options={integrations.map((integration) => {
							return { value: integration.id + "", label: integration.type + " - " + integration.title };
						})}
					/>
					<TextField autoComplete="off" label="Send to:" value={email} onChange={setEmail} />
					<TextField autoComplete="off" label="Subject" value={subject} onChange={setSubject} />
					<TextField
						prefix={
							<Button onClick={generate} loading={generating}>
								Generate
							</Button>
						}
						autoComplete="off"
						label="Instructions"
						multiline={5}
						value={instructions}
						onChange={setInstructions}
					/>
					<TextField autoComplete="off" label="Content" multiline={5} value={content} onChange={setContent} />
				</FormLayout>
			</Card>
		</Page>
	);
}
export default ConversationCreate;
