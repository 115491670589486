import React, { useState } from "react";
import { Page, TextField, Card, FormLayout, Button } from "@shopify/polaris";
import api from "../../Api.js";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function Register() {
	const dispatch = useDispatch<AppDispatch>();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const register = () => {
		setLoading(true);
		api
			.post("/register.json", { email, password })
			.then((result) => {
				if (result.data.success && result.data.token) {
					localStorage.setItem("token", result.data.token);
					api
						.get("/me.json")
						.then((result) => {
							dispatch({ type: "SET_USER", user: result.data });
						})
						.finally(() => {
							setLoading(false);
						});

					return;
				}

				setLoading(false);
			})
			.catch((error) => {
				console.log("error:", error);
				setLoading(false);
			});
	};

	return (
		<Page
			narrowWidth
			title="Register new account"
			breadcrumbs={{
				content: "Go back",
				onAction: () => {
					navigate(-1);
				},
			}}
		>
			<Card sectioned>
				<FormLayout>
					<TextField type="email" label="E-mail" autoComplete="off" value={email} onChange={setEmail} />
					<TextField type="password" label="Password" autoComplete="off" value={password} onChange={setPassword} />
					<Button loading={loading} primary onClick={register}>
						Register
					</Button>
				</FormLayout>
			</Card>
		</Page>
	);
}

export default Register;
