/* eslint-disable func-names */
import axios from "axios";

const API = axios.create({
	baseURL: "https://api.machosales.com",
	timeout: 60000,
});

API.interceptors.request.use(
	async (config) => {
		if (localStorage.getItem("token")) {
			config.headers = Object.assign({}, config.headers, {
				Authorization: `${localStorage.getItem("token")}`,
			});
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

API.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (axios.isCancel(error)) {
			return Promise.reject(error);
		}
		console.error("error:", error.message);

		// console.log("API error:", error);
		return Promise.reject(error.message);
	}
);

export default API;
